import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CRestaurantJumbotron,
  CBreadCrumb,
  LWrap,
  LRestaurantPickup,
  CCard,
  CSectTitle,
  CHeroImg,
  CDefinition,
  LRestaurant,
  CPanelCard,
  LReservation,
  ShopContents,
  LRestaurantContact,
} from '../../../../components/_index';
import shopGet from '../../../../utils/shop-get';
import { shopFixedChoice } from '../../../../utils/shop-choice';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        size="large"
        data={{
          location: {
            floor: '68F',
            category: 'フレンチレストラン',
          },
          logo: {
            src: '/assets/images/restaurants/leciel/logo.svg',
            alt: '',
          },
          title: {
            en: 'PRIVATE ROOM',
            ja: '個室のご案内',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/leciel/private/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/leciel/private/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン&バー',
                path: '/restaurants/',
              },
              {
                label: '68F フレンチレストラン ル シエール',
                path: '/restaurants/leciel/',
              },
            ],
            current: {
              label: '個室のご案内',
            },
          }}
        />
      </CRestaurantJumbotron>
      <section className="l_sect">
        <LWrap>
          <ShopContents
            data={shopFixedChoice(shopGet(), 'leciel_private_room').contents1}
          />
        </LWrap>
      </section>
      <LRestaurantPickup restaurant={['ル シエール']} />
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'プラン', en: 'PLAN' }} />
          <CCard
            data={[
              {
                link: {
                  href: '/anniversary/restaurants_plan/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan.png',
                  alt: '',
                },
                title: <>お祝い・記念日</>,
                content: (
                  <>
                    レストランでの特別なひとときに、より華を添えるプランをご用意しました。心を込めたお料理とおもてなしで素敵な時間を演出いたします。
                  </>
                ),
              },
              {
                link: {
                  href: '/restaurants/private/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan02.png',
                  alt: '',
                },
                title: <>個室のご案内</>,
                content: (
                  <>ゆとりのある個室は、大切な会合やご接待にも最適です。</>
                ),
              },
              {
                link: {
                  href: '/anniversary/restaurants_plan/celebration/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan03.png',
                  alt: '',
                },
                title: <>パーティープラン</>,
                content: (
                  <>
                    納涼会や歓迎会など、パーティーに最適なプランをご紹介いたします。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact selected="yrph-leciel" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
